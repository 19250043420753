.welcome-h1 {
  margin-bottom: 30px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  text-align: left;
}

.welcome-text-2 {
  max-width: 480px;
  color: #353535;
  font-size: 15px;
}

.welcome-section-2 {
  margin-bottom: 20px;
  padding: 35px 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.welcome-section-2.testing {
  padding-top: 90px;
}

.welcome-section-2.teacher-welc {
  padding-top: 55px;
  padding-right: 47px;
  padding-bottom: 100px;
}

.welcome-section-2.no-active {
  padding-top: 30px;
  background-color: #f8f7f4;
  box-shadow: none;
}

.welcome-section-2.no-active.completed-test {
  padding-top: 20px;
  padding-bottom: 20px;
}

.welcome-section-2.after-testing {
  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 80px;
}

.welcome-row-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.welcome-row-2.testing-block {
  margin-right: -50px;
  justify-content: space-between;
}

.welcome-row-2.teacher-welc {
  align-items: flex-start;
}

.welcome-h1-div {
  padding-top: 5px;
  padding-right: 50px;
  flex: 1;
}

.welcome-h3-stud {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}

.welcome-h3-stud.green {
  margin-bottom: 0px;
  color: #36ca75;
}

.welcome-column-stud {
  position: relative;
  display: flex;
  max-width: 400px;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.welcome-column-stud.testing {
  max-width: 460px;
}

.welcome-column-stud.teacher-welc {
  margin-top: 50px;
}

.welcome-column-stud.no-active {
  max-width: 540px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.welcome-column-stud.after-testing {
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
}

.welcome-column-stud.choice-prof {
  max-width: 425px;
}

.welc-num {
  width: 40px;
  height: 40px;
  margin-right: 30px;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #9375fb;
  color: #fff;
  font-size: 25px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.welc-num.no-active {
  background-color: rgba(0, 0, 0, 0.2);
}

.welc-num.complete {
  background-color: #36ca75;
}

.welcome-text-stud {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.6);
  font-size: 15px;
}

.welcome-text-stud.teacher-welc {
  max-width: 340px;
  color: #353535;
  font-size: 15px;
}

.welcome-text-stud.after-testing {
  max-width: 742px;
  color: rgba(53, 53, 53, 0.5);
}

.welcome-text-stud.after-testing._2 {
  margin-top: -20px;
  margin-bottom: 25px;
}

.welcome-image-stud {
  flex: 0 0 auto;
}

.no-active-block-text {
  margin-top: -15px;
  color: #fd651a;
  font-size: 13px;
  line-height: 15px;
}

.welc-completed-test-link {
  font-size: 15px;
}
.after-testing-line {
  height: 2px;
  margin-top: 50px;
  margin-bottom: 40px;
  background-color: rgba(53, 53, 53, 0.05);
}

.recommended-classes-heading {
  margin-bottom: 25px;
  color: #353535;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}

.welcome-row-portfolio {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.welcome-row-portfolio.testing-block {
  margin-right: -50px;
  justify-content: space-between;
}

.welcome-row-portfolio.teacher-welc {
  align-items: flex-start;
}

.welcome-image-portfolio {
  flex: 0 0 auto;
}

.welcome-section-portfolio {
  padding: 60px 50px 40px 100px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.welcome-section-portfolio.testing {
  padding-top: 90px;
}

.welcome-section-portfolio.teacher-welc {
  padding-top: 55px;
  padding-right: 47px;
  padding-bottom: 100px;
}

.welcome-column-portfolio {
  width: 460px;
  max-width: 100%;
  margin-top: 15px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.welcome-column-portfolio.testing {
  width: 380px;
  padding-right: 0px;
}

.welcome-column-portfolio.teacher-welc {
  margin-top: 50px;
}

.welcome-text-portfolio {
  margin-bottom: 35px;
  color: #353535;
  font-size: 15px;
}

.welcome-text-portfolio.teacher-welc {
  max-width: 340px;
  color: #353535;
  font-size: 15px;
}

.welcome-h1-2 {
  max-width: 417px;
  margin-top: 10px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 700;
  text-align: left;
}

.test-progress-block {
  margin-bottom: 90px;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
}

.test-progress-row {
  display: flex;
  padding-top: 35px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: stretch;
}

.test-progress-col {
  position: relative;
  display: flex;
  width: 125px;
  max-width: 25%;
  padding-right: 2px;
  padding-bottom: 25px;
  padding-left: 2px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.test-progress-stage-num {
  display: block;
  width: 35px;
  height: 35px;
  margin-bottom: 7px;
  padding-top: 7px;
  border-radius: 100%;
  background-color: #e4e3e0;
  color: rgba(53, 53, 53, 0.5);
  font-weight: 700;
}

.test-progress-stage-num.active {
  background-color: #9375fb;
  color: #fff;
}

.test-progress-stage-num.done {
  background-color: #36ca75;
  background-image: url('/assets/images/icons/stage-done.svg');
  background-position: 52% 55%;
  background-repeat: no-repeat;
  color: transparent;
}

.test-progress-stage-title {
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
}

.test-progress-stage-title.active {
  color: #9375fb;
  font-weight: 700;
}

.test-progress-line {
  width: 110px;
  height: 3px;
  margin-top: 16px;
  margin-right: -45px;
  margin-left: -45px;
  background-color: #e4e3e0;
}

.test-progress-line.done {
  background-color: #36ca75;
}

.test-progress-white-block {
  padding: 10px 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.test-progress-white-block.testing {
  padding-top: 90px;
}

.test-progress-white-block.teacher-welc {
  padding-top: 55px;
  padding-right: 47px;
  padding-bottom: 100px;
}

.test-progress-white-block.no-active {
  padding-top: 30px;
  background-color: #f8f7f4;
  box-shadow: none;
}

.test-progress-white-block.no-active.completed-test {
  padding-top: 20px;
  padding-bottom: 20px;
}

.test-progress-white-block.after-testing {
  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 80px;
}

.test-progress-white-block.stage-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.test-progress-white-block.stage-3 {
  padding-bottom: 0px;
}

.test-progress-white-block.stage-4 {
  padding-top: 45px;
  padding-right: 70px;
  padding-bottom: 45px;
}

.test-progress-white-block.stage-end {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.welcome-h3-stud-2 {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  text-align: left;
}

.welcome-h3-stud-2.green {
  margin-bottom: 0px;
  color: #36ca75;
}

.welcome-h3-stud-2.stage-end {
  margin-bottom: 10px;
  text-align: center;
}

.welcome-row-3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.welcome-row-3.testing-block {
  margin-right: -50px;
  justify-content: space-between;
}

.welcome-row-3.teacher-welc {
  align-items: flex-start;
}

.welcome-column-stud-2 {
  position: relative;
  max-width: 400px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding-top: 40px;
}

.welcome-column-stud-2.testing {
  max-width: 460px;
}

.welcome-column-stud-2.teacher-welc {
  margin-top: 50px;
}

.welcome-column-stud-2.no-active {
  max-width: 540px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.welcome-column-stud-2.after-testing {
  max-width: 100%;
  margin-top: 0px;
  margin-right: 0px;
}

.welcome-column-stud-2.choice-prof {
  max-width: 425px;
}

.welcome-column-stud-2.st3 {
  max-width: 417px;
}

.welcome-column-stud-2.st4 {
  max-width: 417px;
  padding-top: 0px;
}

.welcome-stage {
  display: inline-block;
  border-bottom: 10px solid rgba(255, 229, 114, 0.8);
  line-height: 4px;
  font-weight: 700;
}

.test-progress-triangle {
  position: absolute;
  left: 50%;
  bottom: 0px;
  display: none;
  transform: translate(-50%, 0px);
}

.test-progress-triangle.active {
  display: inline-block;
}

.progress-block-2 {
  margin-top: 4px;
  margin-bottom: 30px;
}

.progress-block-2.parent-test {
  margin-top: 0px;
}

.welcome-perform-task-h3 {
  margin-top: -30px;
  margin-bottom: 35px;
  font-size: 24px;
  line-height: 25px;
}

.end-stage-text {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.end-stage-container {
  display: inline-block;
  max-width: 489px;
}

.stage-heading-line {
  height: 10px;
  margin-top: -10px;
  background-color: rgba(255, 229, 114, 0.8);
}

.welcome-h3-stage {
  display: inline;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

.welcome-h3-stage.green {
  margin-bottom: 0px;
  color: #36ca75;
}

.welcome-h3-stage.stage-end {
  margin-bottom: 10px;
  text-align: center;
}

//====================================
//===============MEDIA================
//====================================
//============================991px========================
@media screen and (max-width: 991px) {
  .welcome-h1 {
    margin-bottom: 25px;
  }
  .welcome-section-2.testing {
    padding-top: 65px;
  }
  .welcome-section-2.teacher-welc {
    padding-bottom: 80px;
  }
  .welcome-row-2 {
    display: block;
    flex-wrap: nowrap;
  }
  .welcome-row-2.testing-block {
    margin-right: 0px;
  }
  .welcome-row-2._2 {
    display: flex;
  }
  .welcome-h1-div {
    width: 100%;
    padding-top: 25px;
    padding-right: 0px;
    order: 1;
    flex: 0 auto;
  }
  .welcome-column-stud {
    width: auto;
    margin-right: 0px;
    padding-right: 0px;
  }
  .welcome-column-stud.testing {
    width: auto;
    padding-right: 0px;
  }
  .welcome-column-stud.teacher-welc {
    margin-top: 0px;
  }
  .welc-completed-test-link {
    margin-top: 7px;
  }
  .test-result-h1._2 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .welcome-row-portfolio {
    display: block;
    flex-wrap: nowrap;
  }
  .welcome-row-portfolio.testing-block {
    margin-right: 0px;
  }
  .welcome-image-portfolio {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .welcome-section-portfolio {
    padding-top: 50px;
    padding-left: 50px;
  }
  .welcome-section-portfolio.testing {
    padding-top: 65px;
  }
  .welcome-section-portfolio.teacher-welc {
    padding-bottom: 80px;
  }
  .welcome-column-portfolio {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    padding-right: 0px;
  }
  .welcome-column-portfolio.testing {
    width: auto;
    padding-right: 0px;
  }
  .welcome-column-portfolio.teacher-welc {
    margin-top: 0px;
  }
  .welcome-h1-2 {
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .test-progress-white-block.testing {
    padding-top: 65px;
  }
  .test-progress-white-block.teacher-welc {
    padding-bottom: 80px;
  }
  .test-progress-white-block.stage-4 {
    padding-right: 50px;
  }
  .test-progress-white-block.stage-end {
    padding-right: 50px;
  }
  .welcome-row-3 {
    display: block;
    flex-wrap: nowrap;
  }
  .welcome-row-3.testing-block {
    margin-right: 0px;
  }
  .welcome-row-3._2 {
    display: flex;
  }
  .welcome-column-stud-2 {
    width: auto;
    margin-right: 0px;
    padding-right: 0px;
  }
  .welcome-column-stud-2.testing {
    width: auto;
    padding-right: 0px;
  }
  .welcome-column-stud-2.teacher-welc {
    margin-top: 0px;
  }
}
//============================768px========================
@media screen and (max-width: 768px) {
  .welcome-h1 {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 36px;
  }
  .welcome-section-2 {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .welcome-section-2.after-testing {
    padding-right: 20px;
    padding-bottom: 60px;
  }
  .welcome-h1-div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .welcome-h3-stud {
    margin-top: 2px;
  }
  .welcome-column-stud {
    max-width: 500px;
    margin-top: 0px;
  }
  .welc-num {
    width: 30px;
    height: 30px;
    margin-right: 25px;
    font-size: 18px;
    line-height: 30px;
  }
  .welcome-image-portfolio {
    display: block;
  }

  .welcome-section-portfolio {
    margin-right: -20px;
    margin-left: -20px;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .welcome-h1-2 {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 36px;
  }
  .test-progress-block {
    margin-right: -20px;
    margin-left: -20px;
  }
  .test-progress-row {
    padding-right: 5px;
    padding-left: 5px;
  }
  .test-progress-white-block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .test-progress-white-block.after-testing {
    padding-right: 20px;
    padding-bottom: 60px;
  }
  .test-progress-white-block.stage-4 {
    padding-right: 20px;
  }
  .test-progress-white-block.stage-end {
    padding-right: 20px;
  }
  .welcome-h3-stud-2 {
    margin-top: 2px;
  }
  .welcome-column-stud-2 {
    max-width: 500px;
    margin-top: 0px;
  }
  .welcome-stage.end {
    border-bottom-style: none;
    line-height: 25px;
  }
  .welcome-h3-stage {
    margin-top: 2px;
  }
}
//============================450px========================
@media screen and (max-width: 450px) {
  .welcome-section-2.testing {
    padding-top: 50px;
  }

  .welcome-section-2.teacher-welc {
    padding-bottom: 60px;
  }
  .welcome-section-2.after-testing {
    padding-top: 30px;
  }
  .welcome-column-stud {
    display: block;
  }
  .welc-num {
    margin-bottom: 15px;
  }
  .welcome-section-portfolio.testing {
    padding-top: 50px;
  }
  .welcome-section-portfolio.teacher-welc {
    padding-bottom: 60px;
  }
  .test-progress-stage-title {
    font-size: 12px;
  }
  .test-progress-white-block.testing {
    padding-top: 50px;
  }

  .test-progress-white-block.teacher-welc {
    padding-bottom: 60px;
  }
  .test-progress-white-block.after-testing {
    padding-top: 30px;
  }
  .welcome-column-stud-2 {
    display: block;
  }
}
