.professions-card {
  position: relative;
  display: flex;
  padding: 20px 25px 25px;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  color: #353535;
  text-align: center;
  cursor: pointer;
}

.professions-card._2 {
  padding-right: 27px;
  padding-bottom: 40px;
  padding-left: 27px;
  cursor: default;
  @media (max-width: 1024px) {
    padding-right: 15px;
    padding-bottom: 40px;
    padding-left: 15px;
  }
}

.professions-image {
  overflow: hidden;
  width: 140px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  flex: 0 0 auto;
  border-radius: 200px;
  img {
    width: 100%;
  }
  @media (max-width: 1100px) {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }
}

.professions-card-vertical-column {
  margin-bottom: 20px;
  flex: 1;
}

.professions-h3 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.professions-short-text {
  margin: 0;
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.professions-wage {
  display: inline-block;
  min-height: 20px;
  padding-top: 3px;
  padding-left: 30px;
  background-image: url('/assets/images/icons/wage-icon.svg');
  background-position: 0px 0px;
  background-repeat: no-repeat;
  color: #353535;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
}

.rub {
  font-weight: 700;
}
.prof-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.event-card {
  position: relative;
  display: flex;
  overflow: visible;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  color: #353535;
  cursor: pointer;
}

.event-card.fix-height {
  height: 365px;
  flex: 0 auto;
}

.event-heading-wrapper {
  z-index: 1;
}
.event-header {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 20px 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
}

.event-header.full-height {
  height: 150px;
}

.event-type {
  padding: 7px 15px;
  border-radius: 4px;
  background-color: #fff;
  color: #3e70d8;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.17px;
}

.event-type.green {
  color: #2cb855;
}

.event-type.purple {
  color: #9375fb;
}

.event-type.yellow {
  color: #fdcf00;
}

.event-type.orange {
  color: #fd651a;
}

.event-type.blue {
  color: #53a0fd;
}

.event-heading {
  margin-bottom: 0px;
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}

.event-info-div {
  position: relative;
  padding: 25px 25px 25px 20px;
  flex: 1;
}

.event-info-div.adm {
  padding-top: 14px;
  padding-bottom: 5px;
}

.event-info-div.fix-height-card {
  padding-top: 14px;
  padding-bottom: 5px;
}

.event-heart {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
  width: 22px;
  height: 20px;
  background-image: url('/assets/images/icons/heart.svg');
  background-position: 50% 50%;
  background-size: contain;
}

.event-heart.white {
  background-image: url('/assets/images/icons/heart-white.svg');
}

.event-date {
  min-height: 21px;
  margin-right: 30px;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-left: 30px;
  background-image: url('/assets/images/dashboard/calendar-icon.svg');
  background-position: 0px 50%;
  background-repeat: no-repeat;
  color: #979797;
  font-size: 13px;
  line-height: 15px;
}

.event-date.white-text {
  background-image: url('/assets/images/dashboard/calendar-icon-white.svg');
  opacity: 0.5;
}

.event-date.no-date {
  display: none;
}

.event-description {
  margin-bottom: 10px;
  color: #353535;
  font-size: 15px;
}

.event-description.no-date {
  margin-right: 30px;
}

.event-address {
  color: #979797;
  font-size: 13px;
}

.event-address.white-text {
  opacity: 0.5;
}

.event-metro {
  font-size: 13px;
}

.event-price {
  margin-top: 20px;
}

.event-price-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
}

.starting-price {
  margin-right: 10px;
  color: #979797;
  text-decoration: line-through;
}

.starting-price.white-text {
  opacity: 0.5;
}

.discount {
  color: #fd651a;
}

.final-price {
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 0.25px;
}

.final-price.adm {
  margin-right: 5px;
}

.event-gradient-bg {
  position: relative;
  display: block;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  background-image: linear-gradient(45deg, #6679d1, #1e3bd1);
}

.event-gradient-bg.purple {
  background-image: linear-gradient(45deg, #8b74da, #4d26d6);
}

.event-gradient-bg.green {
  background-image: linear-gradient(45deg, #2cb855, #4fd255);
}

.event-gradient-bg.yellow {
  background-image: linear-gradient(45deg, #c8861b, #fdcf00);
}

.event-gradient-bg.orange {
  background-image: linear-gradient(45deg, #e03600, #e27f5f);
}

.event-gradient-bg.blue {
  background-image: linear-gradient(45deg, #53a0fd, #13e5db);
}

.event-gradient-bg.full-height {
  height: auto;
  flex: 1;
  color: #fff;
}

.event-image-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('/assets/images/background/event-bg-2.png');
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0.2;
}

.event-metro-row {
  display: flex;
  align-items: flex-start;
}

.event-metro-row.metro-li {
  margin-right: 10px;
}

.metro-color {
  width: 11px;
  height: 11px;
  max-height: 11px;
  max-width: 11px;
  margin-top: 4px;
  margin-right: 5px;
  flex: 0 0 auto;
  border-radius: 50px;
  background-color: #3e70d8;
}

.metro-color.filter-metro {
  margin-top: 0px;
  border: 2px solid #fff;
}

.metro-color.filter-metro.blue {
  background-color: #36acff;
}

.metro-color.filter-metro.pink {
  background-color: #f743de;
}

.metro-color.filter-metro.orange {
  background-color: #fd651a;
}

.metro-color.filter-metro.peach {
  background-color: #fac176;
}

.metro-color.filter-metro.purple {
  background-color: #795cdc;
}

.metro-color.filter-metro.green {
  background-color: #2cb855;
}

.metro-color.white-border {
  box-shadow: inset 0 0 0 2px #fff;
}

.event-info-row {
  display: flex;
  align-self: stretch;
  line-height: 18px;
}

.event-cost {
  flex: 1;
  font-size: 15px;
  font-weight: 700;
}
.event-info-row-2 {
  display: none;
  align-self: stretch;
  line-height: 18px;
}

.achievement-card {
  position: relative;
  display: flex;
  height: 280px;
  padding: 60px 30px;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ff8a64;
  color: #fff;
  text-align: center;
}

.achievement-card.purple {
  background-color: #9375fb;
}

.achievement-card.blue {
  background-color: #5d7aff;
}

.achievement-card.light-green {
  background-color: #00d5cb;
}

.achievement-card.yellow {
  background-color: #fdcf00;
}

.achievement-card.pink {
  background-color: #ff7aed;
}

.achievement-card.green {
  background-color: #36ca75;
}

.achievement-card.dark-yellow {
  background-color: #ffad00;
}

.achievement-card.brown {
  background-color: #ad896a;
}

.achievement-card-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.achievement-icon-div {
  display: flex;
  height: 51px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.achievement-icon {
  max-height: 100%;
}

.achievement-card-heading {
  margin-bottom: 0px;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.achievement-card-text {
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 0px;
  flex: 0 4.77em;
  color: #fff;
  font-size: 13px;
  line-height: 1.2em;
}

.achievement-card-text.none {
  display: none;
}

.achievement-frame {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  overflow: hidden;
  margin: 20px;
  flex-direction: column;
}

.achievement-frame-oval {
  width: 34px;
  height: 34px;
  margin-top: -15px;
  margin-left: -15px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.achievement-frame-oval._2 {
  margin-right: -15px;
  margin-left: 0px;
}

.achievement-frame-oval._3 {
  margin-top: 0px;
  margin-bottom: -15px;
}

.achievement-frame-oval._4 {
  margin: 0px -15px -15px 0px;
}

.achievement-frame-line {
  flex: 1;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.achievement-frame-line._2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top-style: none;
}

.achievement-frame-line-2 {
  width: 100%;
  flex: 1;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.achievement-frame-row {
  display: flex;
}

.courses-list-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.courses-list-item.archive-short {
  padding-right: 140px;
}

.course-heading {
  font-size: 20px;
  line-height: 25px;
}

.course-heading-link {
  color: #353535;
}

.courses-list-item-link {
  position: relative;
  display: block;
  padding: 20px 90px 25px 30px;
  color: #353535;
}

.courses-list-item-link.archive-short {
  padding-right: 140px;
}

/* ====================================
===============MEDIA================
====================================
============================991px=================== */
@media screen and (max-width: 991px) {
  .professions-card {
    padding-right: 40px;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
  .professions-card._2 {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;
  }
  .professions-image {
    width: 70px;
    height: 70px;
  }
  .prof-column {
    padding-left: 30px;
    align-items: flex-start;
  }
  .prof-column._2 {
    padding-left: 0px;
  }
  .event-card {
    flex-direction: row;
    align-items: stretch;
    text-align: left;
  }
  .event-card.adm {
    display: block;
  }
  .event-card.fix-height {
    height: 266px;
  }
  .event-header {
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .event-header.full-height {
    width: 260px;
    height: auto;
    max-width: 45%;
  }
  .event-heading {
    max-width: 100%;
  }
  .event-info-div.fix-height-card {
    padding-top: 25px;
    padding-right: 50px;
  }
  .event-address.event-pg {
    margin-right: 20px;
  }
  .event-gradient-bg {
    width: 260px;
    height: auto;
    max-width: 45%;
  }
  .event-gradient-bg.full-height {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  .event-gradient-bg.adm {
    flex: 1;
    border-bottom-left-radius: 0px;
  }
  .event-image-bg.adm {
    display: flex;
  }
  .event-info-row {
    display: block;
  }
  .event-info-row-2 {
    display: block;
  }
  .courses-list-item-link {
    padding-right: 30px;
  }
}
/* //==============768px============== */
@media screen and (max-width: 768px) {
  .professions-card {
    padding-bottom: 20px;
  }
  .professions-card._2 {
    padding-bottom: 25px;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
  .professions-image {
    margin-top: 12px;
    width: 50px;
    height: 50px;
    margin-bottom: 0px;
  }
  .professions-card-vertical-column {
    margin-bottom: 5px;
  }

  .prof-column {
    padding-left: 25px;
  }
  .prof-column._2 {
    margin-top: 10px;
    padding-left: 25px;
  }
  .event-card.adm {
    flex: 0 auto;
  }
  .event-card.fix-height {
    height: 150px;
  }
  .event-header.full-height {
    width: auto;
    max-width: 100%;
  }
  .event-type {
    padding: 4px 10px 5px;
  }
  .event-heading {
    margin-top: 15px;
    margin-bottom: 15px;
    flex: 1;
    font-size: 16px;
    line-height: 18px;
  }
  .event-info-div {
    display: none;
  }
  .event-gradient-bg {
    width: 100%;
    height: 150px;
    max-width: 100%;
    border-radius: 10px;
  }
  .event-gradient-bg.full-height {
    height: 150px;
  }
  .event-gradient-bg.adm {
    border-bottom-right-radius: 0px;
  }
  .event-cost {
    font-size: 13px;
  }
  .event-info-row-2 {
    display: flex;
    font-size: 13px;
    line-height: 15px;
  }
  .course-heading {
    font-size: 18px;
    line-height: 23px;
  }
}
/* //=========================450p============== */
@media screen and (max-width: 450px) {
  .event-card {
    flex-direction: column;
  }
  .event-gradient-bg {
    width: auto;
    height: 150px;
    max-width: 100%;
  }
  .achievement-card {
    height: 220px;
    padding: 40px 20px;
  }
  .achievement-frame {
    margin: 10px;
  }
  .achievement-icon-div {
    height: 25px;
  }
  .achievement-card-heading {
    font-size: 10px;
    letter-spacing: 1px;
  }
  .achievement-card-text {
    margin-top: 10px;
    font-size: 10px;
    line-height: 12px;
  }
  .courses-list-item.archive-short {
    padding-right: 30px;
  }
  .courses-list-item-link.archive-short {
    padding-right: 30px;
  }
}
