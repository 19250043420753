@import './app/app.component.scss';
@import './styles/normalize.component';
@import './styles/webflow.component.scss';
@import './styles/content/base.scss';
@import './styles/content/cards.scss';
@import './styles/content/login&registration.scss';
@import './styles/content/menus&filters.scss';
@import './styles/content/news.scss';
@import './styles/content/panels.scss';
@import './styles/content/school-class.scss';
@import './styles/content/test-results.scss';
@import './styles/content/user-profile.scss';
@import './styles/content/welcome.scss';
@import './styles/controls/banners.scss';
@import './styles/controls/buttons.scss';
@import './styles/controls/dropdowns.scss';
@import './styles/controls/elements.scss';
@import './styles/controls/forms.scss';
@import './styles/controls/tabs.scss';
@import './styles/layouts/footer.scss';
@import './styles/layouts/header.scss';
@import './styles/layouts/popup&overlay.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

.disabled-btn {
  background-color: rgba(196, 196, 196, 0.2);
  pointer-events: none;
  color: rgba(53, 53, 53, 0.5);
  box-shadow: 0 5px 20px 0 rgba(51, 51, 51, 0.3);
}

.clear_float {
  display: block;
  clear: both;
}

.no-pointer {
  pointer-events: none;
}

.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  display: none;
}

.w-center {
  text-align: center;
}

.modal-open {
  overflow-y: hidden;
}

/*button reset styles*/
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

/*======== ПОЛЬЗОВАТЕЛЬСКИЕ СОГЛАШЕНИЯ ========*/
.terms-text {
  font-size: 11px;
  line-height: 15px;
  font-weight: 900;
}

.terms {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 20px;
  align-items: center;
}

.checkbox-terms {
  width: 32px;
  height: 32px;
  flex: 0 0 auto;
  border: 3px solid #9375fb;
  border-radius: 4px;
  margin-right: 20px;
}

.checkbox-terms-grn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  border: 3px solid $green_new;
  border-radius: 4px;
  margin-right: 20px;
  @media (max-width: 450px) {
    margin-right: 12px;
  }
}

.checked-terms {
  background: #9375fb url(/assets/images/icons/checked_blank.svg) no-repeat center;
}

.checked-terms-grn {
  background: $green_new url(/assets/images/icons/checked_new.svg) no-repeat center;
}

/*======== ПОЛЬЗОВАТЕЛЬСКИЕ СОГЛАШЕНИЯ END ========*/

.text-container {
  margin: 0 auto;

  .descr {
    display: block;
    margin: 10px auto;
    width: 600px;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
  }

  .title {
    display: block;
    margin: 0 auto 10px auto;
    font-family: ALSSchlangesans;
    font-weight: bold;
    line-height: 40px;
    width: 755px;
    text-align: center;
    font-size: 36px;

    .yes {
      color: #8bab2c;
    }

    .no {
      color: #f35269;
    }
  }
}

.empty-row {
  .empty-row-small {
    height: 70.4px !important;
  }

  .empty-row-large {
    height: 133.4px !important;
  }
}

.pdf_container {
  height: 100vh;
}

.testing-logo-link {
  img {
    height: 39px;
    width: auto;
  }
}

.logo-link {
  position: absolute;
  left: 32px;
  top: 16px;

  img {
    width: 40px;
    height: auto;
  }

  @media (max-width: 450px) {
  }
}

.single-autocomplete {
  height: fit-content;
}
