.news-block {
  display: flex;
  margin-right: -30px;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
}

.news-column {
  max-width: 490px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.news-card {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  color: #353535;
}

.news-card-image-link {
  display: block;
  height: 210px;
  background-image: url('/assets/images/dashboard/Bitmap-1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.news-info-div {
  padding: 25px 40px 35px;
  font-size: 15px;
}

.news-card-heading {
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}

.news-card-heading-link {
  color: #353535;
}

.news-card-text {
  margin-bottom: 30px;
  color: #353535;
}

.news-card-row {
  display: flex;
  color: rgba(53, 53, 53, 0.5);
}

.news-card-row.article-page {
  justify-content: space-between;
}

.news-card-date {
  margin-right: 15px;
  flex: 1;
  font-size: 13px;
}

.news-card-date.article-page {
  flex: 0 auto;
}

.news-main-column {
  max-width: 50%;
}

.news-page-section {
  position: relative;
  display: flex;
  margin-top: -15px;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-size: 15px;
}

.news-page-section.parent-profile {
  display: block;
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
}

.news-page-section.create {
  padding: 45px 50px 50px;
}

.news-page-container {
  width: 700px;
  max-width: 100%;
}

.news-page-h1 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 35px;
  line-height: 40px;
}

.article-content {
  font-size: 15px;
}

.article-content p {
  margin-bottom: 15px;
  color: #353535;
}

.article-content h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
}

.article-content li {
  margin-bottom: 10px;
}

.article-content.edit-page {
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(53, 53, 53, 0.1);
}

.article-content h4 {
  margin-bottom: 15px;
}

.article-content h3 {
  margin-top: 20px;
}

.article-subtitle {
  margin-bottom: 35px;
  color: #353535;
  font-weight: 700;
}

.article-video {
  margin-bottom: 35px;
}

.article-image {
  margin-bottom: 20px;
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .news-card-image-link {
    height: 18vw;
  }
  .news-info-div {
    padding-right: 30px;
    padding-bottom: 25px;
    padding-left: 30px;
    font-size: 14px;
    line-height: 19px;
  }
  .news-card-heading {
    font-size: 18px;
    line-height: 24px;
  }
  .news-card-row {
    display: block;
  }
  .news-card-row.article-page {
    display: flex;
  }
  .news-card-date {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .news-card-date.article-page {
    margin-right: 15px;
    margin-bottom: 0px;
  }
  .news-page-section {
    margin-top: 0px;
    padding: 35px 40px 70px;
  }
  .news-page-section.parent-profile {
    padding-right: 30px;
    padding-left: 30px;
  }
  .news-page-section.create {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .news-block {
    margin-right: -20px;
  }
  .news-column {
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .news-card-image-link {
    height: 22vw;
  }
  .news-info-div {
    padding-right: 20px;
    padding-left: 20px;
  }
  .news-card-row.article-page {
    flex-wrap: wrap;
  }
  .news-card-date.article-page {
    margin-bottom: 5px;
  }
  .news-page-section {
    margin-top: 15px;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
  }
  .news-page-section.parent-profile {
    padding-right: 20px;
    padding-left: 20px;
  }
  .news-page-section.create {
    padding-top: 35px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .news-page-h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 450px) {
  .news-block {
    display: block;
    margin-right: 0px;
  }
  .news-column {
    padding-right: 0px;
  }
  .news-card-image-link {
    height: 40vw;
  }
  .news-card-date.article-page {
    flex: 0 auto;
  }
  .news-main-column {
    max-width: 100%;
  }
  .news-page-h1 {
    font-size: 26px;
    line-height: 34px;
  }
  .news-card-date-column {
    flex: 0 auto;
  }
}
