.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  transform: translate(0px, 0px);
}

.menu-link {
  display: flex;
  padding: 58px 0px 10px;
  flex-direction: column;
  align-items: center;
  background-image: url('/assets/images/dashboard/Main-Grey.svg');
  background-position: 50% 15px;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  color: rgba(53, 53, 53, 0.5);
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
}

.menu-link:hover {
  background-color: rgba(53, 53, 53, 0.02);
  background-image: url('/assets/images/dashboard/Main-VIolet.svg');
  opacity: 1;
  filter: none;
  color: #9375fb;
}

.menu-link.w--current {
  background-image: url('/assets/images/dashboard/Main-VIolet.svg');
  color: #9375fb;
}

.menu-link.adm {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 13px;
  line-height: 15px;
}

.menu-link.adm.w--current {
  filter: none;
  color: #9375fb;
}

.menu-link.mosruabout {
  background-image: url('/assets/images/menu/redHat_grey.svg');
  &:hover {
    background-image: url('/assets/images/menu/redHat.svg');
  }
}
.menu-link.mosruabout.w--current {
  background-image: url('/assets/images/menu/redHat.svg');
}

.menu-link.adm.schools {
  background-image: url('/assets/images/dashboard/School-Grey.svg');
}

.menu-link.adm.schools:hover {
  background-image: url('/assets/images/dashboard/School-VIolet.svg');
}

.menu-link.adm.companies {
  background-image: url('/assets/images/dashboard/Company_Grey.svg');
}

.menu-link.adm.companies:hover {
  background-image: url('/assets/images/dashboard/Company_VIolet.svg');
}

.menu-link.adm.companies.w--current {
  background-image: url('/assets/images/dashboard/Company_VIolet.svg');
}

.menu-link.adm.users {
  background-image: url('/assets/images/dashboard/Users-Grey.svg');
}

.menu-link.adm.users:hover {
  background-image: url('/assets/images/dashboard/Users-VIolet.svg');
}

.menu-link.adm.school-2 {
  background-image: url('/assets/images/dashboard/School-Grey.svg');
}

.menu-link.adm.school-2:hover {
  background-image: url('/assets/images/dashboard/School-VIolet.svg');
}

.menu-link.adm.school-2.w--current {
  background-image: url('/assets/images/dashboard/School-VIolet.svg');
}

.menu-link.test {
  background-image: url('/assets/images/dashboard/Test-Grey.svg');
}

.menu-link.test:hover {
  background-image: url('/assets/images/dashboard/Test-VIolet.svg');
}

.menu-link.test.w--current {
  background-image: url('/assets/images/dashboard/Test-VIolet.svg');
}

.menu-link.portfolio {
  background-image: url('/assets/images/dashboard/Portfolio-Grey.svg');
}

.menu-link.portfolio:hover {
  background-image: url('/assets/images/dashboard/Portfolio-VIolet.svg');
}

.menu-link.portfolio.w--current {
  background-image: url('/assets/images/dashboard/Portfolio-VIolet.svg');
}

.menu-link.professions {
  background-image: url('/assets/images/dashboard/Professions-Grey.svg');
}

.menu-link.professions:hover {
  background-image: url('/assets/images/dashboard/Professions-VIolet.svg');
}

.menu-link.professions.w--current {
  background-image: url('/assets/images/dashboard/Professions-VIolet.svg');
}

.menu-link.courses {
  background-image: url('/assets/images/dashboard/Course_Grey.svg');
}

.menu-link.courses:hover {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}

.menu-link.courses.w--current {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}

.menu-link.events {
  background-image: url('/assets/images/dashboard/Events-Grey.svg');
}

.menu-link.events:hover {
  background-image: url('/assets/images/dashboard/Events-VIolet.svg');
}

.menu-link.events.w--current {
  background-image: url('/assets/images/dashboard/Events-VIolet.svg');
}

.menu-link.internships {
  background-image: url('/assets/images/dashboard/Course_Grey.svg');
}

.menu-link.internships:hover {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}

.menu-link.internships.w--current {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}
.menu-link.news {
  background-image: url('/assets/images/dashboard/News-Grey.svg');
}

.menu-link.news:hover {
  background-image: url('/assets/images/dashboard/News-VIolet.svg');
}

.menu-link.news.w--current {
  background-image: url('/assets/images/dashboard/News-VIolet.svg');
}

.menu-link.schools {
  background-image: url('/assets/images/dashboard/School-Grey.svg');
}

.menu-link.schools:hover {
  background-image: url('/assets/images/dashboard/School-VIolet.svg');
}

.menu-link.users.w--current {
  background-image: url('/assets/images/dashboard/Users-VIolet.svg');
}

.menu-img {
  max-height: 100%;
}

.menu-separator {
  height: 13%;
  max-height: 12px;
  min-height: 5px;
  flex: 0 12px;
}

.menu-separator._2 {
  flex-basis: 5px;
}

.parent-menu-link {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 10px 20px;
  border-radius: 100px;
  color: #353535;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.parent-menu-link:hover {
  background-color: rgba(53, 53, 53, 0.05);
}

.parent-menu-link.w--current {
  background-color: #9375fb;
  color: #fff;
}

.professions-menu-row-2 {
  display: flex;
  flex-wrap: wrap;
}

.professions-menu-link {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 10px 20px;
  border-radius: 100px;
  color: #353535;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.professions-menu-link:hover {
  background-color: rgba(53, 53, 53, 0.05);
}

.professions-menu-link.w--current {
  background-color: #9375fb;
  color: #fff;
}

.professions-menu-link.no-active {
  color: rgba(53, 53, 53, 0.2);
  cursor: default;
}

.professions-menu {
  display: flex;
  margin-bottom: 36px;
}

.professions-menu.after-testing.rec {
  justify-content: center;
}

.menu-link-block {
  display: flex;
  min-height: 57px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease;
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
}

.menu-link-block:hover {
  background-color: rgba(53, 53, 53, 0.02);
  opacity: 1;
  filter: none;
  color: #9375fb;
}

.menu-link-block.test {
  background-image: url('/assets/images/dashboard/Test-Grey.svg');
}

.menu-link-block.test:hover {
  background-image: url('/assets/images/dashboard/Test-VIolet.svg');
}

.menu-link-block.test.w--current {
  background-image: url('/assets/images/dashboard/Test-VIolet.svg');
}

.menu-link-block.portfolio {
  background-image: url('/assets/images/dashboard/Portfolio-Grey.svg');
}

.menu-link-block.portfolio:hover {
  background-image: url('/assets/images/dashboard/Portfolio-VIolet.svg');
}

.menu-link-block.portfolio.w--current {
  background-image: url('/assets/images/dashboard/Portfolio-VIolet.svg');
}

.menu-link-block.professions {
  background-image: url('/assets/images/dashboard/Professions-Grey.svg');
}

.menu-link-block.professions:hover {
  background-image: url('/assets/images/dashboard/Professions-VIolet.svg');
}

.menu-link-block.professions.w--current {
  background-image: url('/assets/images/dashboard/Professions-VIolet.svg');
}

.menu-link-block.courses {
  background-image: url('/assets/images/dashboard/Course_Grey.svg');
}

.menu-link-block.courses:hover {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}

.menu-link-block.courses.w--current {
  background-image: url('/assets/images/dashboard/Course_VIolet.svg');
}

.menu-link-block.events {
  background-image: url('/assets/images/dashboard/Events-Grey.svg');
}

.menu-link-block.events:hover {
  background-image: url('/assets/images/dashboard/Events-VIolet.svg');
}

.menu-link-block.events.w--current {
  background-image: url('/assets/images/dashboard/Events-VIolet.svg');
}

.menu-link-block.news {
  background-image: url('/assets/images/dashboard/News-Grey.svg');
}

.menu-link-block.news:hover {
  background-image: url('/assets/images/dashboard/News-VIolet.svg');
}

.menu-link-block.news.w--current {
  background-image: url('/assets/images/dashboard/News-VIolet.svg');
}

.menu-link-block.schools {
  background-image: url('/assets/images/dashboard/School-Grey.svg');
}

.menu-link-block.schools:hover {
  background-image: url('/assets/images/dashboard/School-VIolet.svg');
}

.menu-link-block.users.w--current {
  background-image: url('/assets/images/dashboard/Users-VIolet.svg');
}

.menu-link-block.active {
  color: #9375fb;
}

.menu-icon-div {
  position: relative;
  display: flex;
  max-height: 40px;
  min-height: 24px;
  justify-content: center;
  align-items: stretch;
}

.parent-menu {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 6px;
}

.parent-menu-row-2 {
  display: flex;
  flex-wrap: wrap;
}

.mobile-menu-button {
  display: none;
}
.mobile-menu {
  display: none;
}
.tabs-menu-div {
  display: flex;
  min-height: 110px;
}

.tabs-menu-div.parent-tabs {
  min-height: auto;
}

.tab-menu-professions {
  position: relative;
  display: flex;
  margin-bottom: 50px;
  padding-top: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-top: 2px solid rgba(53, 53, 53, 0.05);
}

.tabs-professions-link {
  display: inline-block;
  margin-right: 45px;
  padding-top: 4px;
  padding-bottom: 4px;
  flex: 0 0 auto;
  color: #353535;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}

.tabs-professions-link.w--current {
  color: #9375fb;
  font-weight: 700;
}
.professions-column {
  flex: 1;
}

.tab-menu-favorites {
  position: relative;
  display: flex;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.03);
}
.age-div {
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
}

.event-age {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 100px;
  color: #353535;
}

.event-age:hover {
  background-color: rgba(26, 30, 38, 0.05);
}

.event-age.w--current {
  background-color: #9375fb;
  color: #fff;
}
.filter-price-row {
  display: flex;
  align-items: center;
}

.price-field {
  width: 80px;
  height: auto;
  margin-bottom: 0px;
  padding: 5px 0px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.1);
  color: #353535;
}

.price-field:focus {
  border-color: transparent transparent #5d7aff;
  border-bottom-style: solid;
}

.price-field::-webkit-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.price-field:-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.price-field::-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.price-field::placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.price-filter-text {
  margin-right: 10px;
}
.filter-price-column {
  display: flex;
  align-items: center;
  flex: 1;
}
.events-filters {
  display: flex;
  margin-bottom: 30px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.events-filters.adm {
  margin-top: 40px;
}

.filter-metro-row {
  display: flex;
  align-items: flex-start;
}

.admin-menu {
  display: flex;
  margin-bottom: 25px;
  align-items: flex-start;
}

.admin-menu-col {
  flex: 1;
  text-align: right;
}

.admin-menu-row {
  display: flex;
  flex-wrap: wrap;
}

.admin-menu-row._2 {
  flex-wrap: nowrap;
}
//================================================
//==================MEDIA=========================
//================================================

@media screen and (max-width: 991px) {
  .menu-link {
    padding-top: 30px;
    padding-bottom: 10px;
    background-position: 50% 4px;
    background-size: auto 24px;
  }
  .parent-menu {
    flex-wrap: wrap;
  }
  .professions-menu {
    flex-wrap: wrap;
  }
  .events-filters {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768px) {
  .menu {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    width: 100%;
    padding: 80px 30px;
    background-color: #fff;
    transform: translate(100%, 0px);
  }

  .mobile-menu {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1500;
    overflow: auto;
    width: 100%;
    background-color: rgba(147, 117, 251, 0.5);
  }
  .mobile-menu-header {
    display: flex;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .mobile-menu-logo-div {
    padding-left: 30px;
    flex: 1;
    text-align: right;
  }
  .mobile-user-div {
    padding: 15px 20px 15px 10px;
    border-bottom: 1px solid rgba(53, 53, 53, 0.05);
  }
  .mobile-menu-bg {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(147, 117, 251, 0.5);
  }
  .mobile-menu-container {
    position: relative;
    left: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 2;
    width: 285px;
    min-height: 100vh;
    padding-bottom: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  }
  .mobile-menu-button {
    display: flex;
    width: 22px;
    height: 22px;
    margin-top: 12px;
    justify-content: center;
    align-items: center;
  }
  .mobile-menu-button.fixed {
    margin-top: 0px;
  }
  .mobile-menu-icon-div {
    display: flex;
    width: 24px;
    height: 12px;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-line {
    height: 3px;
    background-color: #353535;
  }

  .menu-link {
    display: block;
    padding: 20px 20px 20px 70px;
    background-position: 22px 48%;
    background-size: 25px;
    background-repeat: no-repeat;
    opacity: 1;
    filter: none;
    font-size: 16px;
    line-height: 23px;
  }
  .menu-link.w--current {
    background-image: url('/assets/images/dashboard/School-VIolet.svg');
    color: #9375fb;
    font-weight: 400;
  }
  .menu-link.users {
    background-image: url('/assets/images/dashboard/Users-Grey.svg');
  }
  .menu-link.users:hover {
    background-image: url('/assets/images/dashboard/Users-VIolet.svg');
  }
  .menu-link.companies {
    background-image: url('/assets/images/dashboard/Company_Grey.svg');
  }
  .menu-link.companies:hover {
    background-image: url('/assets/images/dashboard/Company_VIolet.svg');
  }
  .parent-menu-link {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 auto;
    font-size: 13px;
    line-height: 18px;
  }

  .professions-menu-link {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
  }

  .menu-link-block {
    display: block;
    padding: 20px 20px 20px 70px;
    background-position: 22px 48%;
    background-size: 25px;
    background-repeat: no-repeat;
    opacity: 1;
    filter: none;
    font-size: 16px;
    line-height: 23px;
  }
  .menu-link-block.w--current {
    background-image: url('/assets/images/dashboard/School-VIolet.svg');
    color: #9375fb;
    font-weight: 400;
  }
  .menu-link-block.users {
    background-image: url('/assets/images/dashboard/Users-Grey.svg');
  }
  .menu-link-block.users:hover {
    background-image: url('/assets/images/dashboard/Users-VIolet.svg');
  }
  .menu-link-block.companies {
    background-image: url('/assets/images/dashboard/Company_Grey.svg');
  }
  .menu-link-block.companies:hover {
    background-image: url('/assets/images/dashboard/Company_VIolet.svg');
  }
  .parent-menu {
    overflow: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
    flex-wrap: nowrap;
  }
  .parent-menu-row-2 {
    padding-right: 50px;
    flex-wrap: nowrap;
  }
  .admin-menu {
    overflow: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px;
  }
  .admin-menu-col {
    padding-right: 20px;
  }
  .admin-menu-row {
    flex-wrap: nowrap;
    flex: 0 0 auto;
  }
  .professions-menu {
    overflow: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
    flex-wrap: nowrap;
  }
  .professions-menu.after-testing.rec {
    justify-content: flex-start;
  }

  .professions-menu-row-2 {
    padding-right: 130px;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 450px) {
  .mobile-menu-container {
    max-width: 80%;
  }
  .professions-menu-row-2 {
    padding-right: 110px;
  }
  .admin-menu-row {
    padding-right: 0px;
  }
  .age-div {
    flex-wrap: wrap;
  }
  .event-age {
    min-width: 81px;
  }
}
