.user-dropdown {
  color: #353535;
}

.user-dropdown.w--current {
  color: #9375fb;
  font-weight: 400;
}

.user-photo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100px;
  background-image: url('/assets/images/dashboard/user-photo.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.user-name {
  font-size: 16px;
}

.user-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-dropdown {
  width: 60px;
  margin-left: 2px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top-right-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
}

.tabs-dropdown:hover {
  background-color: rgba(53, 53, 53, 0.04);
}

.tabs-dropdown.active {
  background-color: rgba(53, 53, 53, 0.04);
}
.tabs-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-dropdown-list {
  background-color: transparent;
}

.tabs-dropdown-list.w--open {
  right: 0px;
  width: 510px;
  margin-top: -10px;
}

.tabs-dd-bg {
  padding: 20px 20px 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}

.tabs-dd-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tabs-dd-column {
  width: 33.33333333%;
  padding-bottom: 10px;
}

.tabs-dd-column.parent-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tabs-dd-link {
  display: block;
  padding: 15px 10px;
  border-radius: 10px;
  color: #353535;
  font-size: 16px;
  text-align: center;
}

.tabs-dd-link:hover {
  background-color: rgba(53, 53, 53, 0.02);
}

.tabs-dd-link.add-tab {
  color: rgba(53, 53, 53, 0.2);
}

.tabs-dd-link.add-tab:hover {
  color: #5d7aff;
}

.tabs-dd-link.parent-tabs {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.dropdown-list {
  padding-top: 10px;
}

.dropdown-list.w--open {
  right: 0px;
  background-color: transparent;
}

.professions-dd-bg {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}

.professions-dd-link {
  padding: 10px 20px 10px 40px;
}
.menu-professions-dropdown {
  position: relative;
  right: 0px;
  margin-left: 0px;
}

.professions-dd-toggle {
  padding: 5px 35px 5px 15px;
  border-radius: 100px;
  box-shadow: inset 0 0 0 2px rgba(53, 53, 53, 0.1);
  font-size: 15px;
  line-height: 20px;
}

.professions-dd-icon {
  margin-right: 15px;
}

.class-dropdown {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  width: 20px;
  height: 30px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.class-dropdown:hover {
  z-index: 4;
}

.class-dropdown.active {
  background-color: rgba(53, 53, 53, 0.04);
}

.class-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.class-dropdown-list {
  background-color: transparent;
}

.class-dropdown-list.w--open {
  right: 0px;
  width: 180px;
  padding-top: 5px;
}

.class-dd-option {
  display: block;
  margin-bottom: 20px;
  color: #353535;
  font-size: 15px;
}

.class-dd-bg {
  padding: 25px 20px 5px 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}

.professions-dropdown {
  margin-right: 0px;
  margin-left: 0px;
}

.professions-dropdown-toggle {
  padding: 5px 45px 5px 20px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(53, 53, 53, 0.1);
  border-radius: 100px;
  font-size: 15px;
}

.professions-dropdown-toggle.active {
  border-color: #9375fb;
  background-color: #9375fb;
  color: #fff;
}

.professions-dropdown-toggle.cat {
  min-width: 170px;
}

.professions-dd-icon-2 {
  margin-right: 20px;
  font-size: 17px;
}

.filter-div {
  margin-bottom: 10px;
}

.filter-div.cat {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dd-prof-filter-list.w--open {
  padding-top: 10px;
  background-color: transparent;
}

.dd-prof-filter-list.right-position {
  right: 0px;
}

.dd-prof-filter-div {
  padding: 20px 20px 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  font-size: 15px;

  &.limited-height{
    max-height: 175px;
    overflow-y: scroll;
  }
}

.dd-prof-filter-div._2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 440px;
  height: 440px;
  padding-top: 25px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dd-prof-filter-div._2.age-filter-div {
  width: 390px;
  height: auto;
  padding-bottom: 15px;
}

.dd-prof-filter-div._2.metro-filter-div {
  width: 360px;
}

.dd-prof-filter-div._2.add-prof {
  width: 490px;
  height: 350px;
  padding-bottom: 25px;
}

.dd-prof-filter-link {
  margin-bottom: 10px;
  padding: 5px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.dd-prof-filter-link:hover {
  background-color: rgba(26, 30, 38, 0.05);
}

.filter-list-heading {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 700;
}

.scroll-block {
  position: relative;
  overflow: auto;
  margin-top: 5px;
  margin-right: -35px;
  margin-left: -20px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.scroll-block.add-prof {
  padding-top: 0px;
  padding-bottom: 0px;
}

.prof-categories-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 5px 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 20px;
  color: #353535;
  cursor: pointer;
}

.prof-categories-link:hover {
  background-color: rgba(26, 30, 38, 0.05);
}

.prof-categories-link.w--current {
  background-color: #9375fb;
  color: #fff;
}

.prof-categories-link.dd-link {
  margin-bottom: 0px;
}

.category-num {
  display: inline-block;
  width: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: right;
}

.category-text-link {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.filter-list-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-bottom: 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.prof-categories-links {
  padding-right: 15px;
}

.event-category-link-dd {
  width: 100%;
  margin-bottom: 10px;
}

.event-categories-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 5px 45px 5px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  color: #353535;
  cursor: pointer;
}

.event-categories-link:hover {
  background-color: rgba(26, 30, 38, 0.05);
}

.event-categories-link.w--current {
  background-color: #9375fb;
  color: #fff;
}

.event-categories-link.dd-link {
  margin-bottom: 0px;
}

.event-categories-link.dd-link.w--open {
  color: #353535;
}

.event-categories-link.dd-link.active {
  background-color: #9375fb;
  color: #fff;
}

.event-category-dd-icon {
  top: -1px;
}

.event-dd-second {
  position: static;
}

.event-dd-second.w--open {
  padding-top: 10px;
  padding-left: 30px;
  background-color: transparent;
}

.add-achieve-dropdown-toggle {
  padding: 5px 45px 5px 20px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(53, 53, 53, 0.1);
  border-radius: 100px;
  font-size: 15px;
}

.add-achieve-dropdown-toggle.active {
  border-color: #9375fb;
  background-color: #9375fb;
  color: #fff;
}

.add-achieve-dropdown-toggle.cat {
  min-width: 170px;
}

.add-achieve-dropdown {
  margin-right: 0px;
  margin-left: 0px;
}

.dd-add-achieve.w--open {
  padding-top: 10px;
  background-color: transparent;
}

.dd-add-achieve.right-position {
  right: 0px;
}

.add-achievement-dd-link {
  margin-bottom: 10px;
  padding: 5px 20px;
  border-radius: 20px;
  color: #353535;
  cursor: pointer;
}

.add-achievement-dd-link:hover {
  background-color: rgba(26, 30, 38, 0.05);
}

.add-achievement-dd-link.w--current {
  background-color: #9375fb;
  color: #fff;
}

.add-achievement-dd-link.dd-link {
  margin-bottom: 0px;
}
//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .notice-dropdown {
    margin-right: 35px;
  }

  .dd-prof-filter-list.w--open {
    margin-left: -40px;
  }
  .dd-prof-filter-div._2.metro-filter-div {
    width: 330px;
  }
}
@media screen and (max-width: 768px) {
  .user-dropdown {
    display: none;
  }
  .user-dropdown.mob {
    display: block;
  }
  .user-photo.mob {
    margin-right: 20px;
  }
  .user-name.mob {
    font-size: 18px;
    line-height: 23px;
  }
  .tabs-dropdown-list.w--open {
    max-width: 420px;
  }
  .tabs-dd-link {
    font-size: 14px;
  }
  .text-block.dd-ad-child {
    line-height: 16px;
  }
  .class-dropdown-list.w--open {
    max-width: 420px;
  }
  .professions-dropdown-toggle {
    padding-right: 35px;
    padding-left: 15px;
    font-size: 14px;
  }
  .professions-dd-icon-2 {
    margin-right: 10px;
  }
  .dd-prof-filter-list.w--open {
    left: 0px;
    right: auto;
    margin-left: 0px;
  }
  .dd-prof-filter-div {
    padding-right: 15px;
    padding-left: 15px;
  }
  .dd-prof-filter-div._2 {
    padding-left: 30px;
  }
  .dd-prof-filter-link {
    padding-right: 15px;
    padding-left: 15px;
  }
  .scroll-block {
    margin-left: -15px;
  }
  .prof-categories-link {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .event-categories-link {
    padding-left: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .add-achieve-dropdown-toggle {
    padding-right: 35px;
    padding-left: 15px;
    font-size: 14px;
  }
  .dd-add-achieve.w--open {
    left: 0px;
    right: auto;
    margin-left: 0px;
  }
  .add-achievement-dd-link {
    margin-bottom: 5px;
    padding-left: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .dd-prof-filter-div {
    a {
      white-space: normal;
    }
  }
  .professions-dropdown-toggle {
    div {
      white-space: normal;
    }
  }
}
@media screen and (max-width: 450px) {
  .tabs-dropdown {
    max-width: 50px;
    min-width: 40px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .tabs-dropdown-list.w--open {
    max-width: 260px;
  }
  .tabs-dd-column {
    width: 50%;
  }
  .tabs-dd-column.parent-tabs {
    width: 100%;
  }
  .tabs-dd-link {
    font-size: 12px;
    line-height: 18px;
  }
  .tabs-dd-link.parent-tabs {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .class-dropdown {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .class-dropdown-list.w--open {
    max-width: 260px;
  }
  .dd-prof-filter-div._2 {
    max-width: 89vw;
  }
}
