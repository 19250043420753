.tabs-professions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tabs-menu-prof {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: -30px -30px -10px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tab-link-par {
  position: relative;
  min-width: 140px;
  padding: 20px 30px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
  box-shadow: inset 0 -10px 0 0 #fff;
  color: #353535;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.tab-link-par:hover {
  background-color: rgba(53, 53, 53, 0.02);
}

.tab-link-par.w--current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link-par.add-tab {
  color: rgba(53, 53, 53, 0.2);
}

.tab-link-par.add-tab:hover {
  color: #5d7aff;
}

.tab-link-par.parent-tabs {
  max-width: 33%;
  padding-right: 50px;
  padding-bottom: 30px;
  padding-left: 50px;
  text-align: left;
}

.tab-link-par.current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link-par.adm {
  padding-top: 30px;
  padding-bottom: 40px;
}

.tab-link-par.login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
  padding-bottom: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 24px;
  line-height: 30px;
}

.tab-link-prof {
  position: relative;
  min-width: 140px;
  padding: 30px 30px 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
  box-shadow: inset 0 -10px 0 0 #fff;
  color: #353535;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.tab-link-prof:hover {
  background-color: rgba(53, 53, 53, 0.02);
}

.tab-link-prof.w--current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link-prof.add-tab {
  color: rgba(53, 53, 53, 0.2);
}

.tab-link-prof.add-tab:hover {
  color: #5d7aff;
}

.tab-link-prof.parent-tabs {
  padding-right: 50px;
  padding-bottom: 30px;
  padding-left: 50px;
  text-align: left;
}

.tab-link-prof.current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link-prof.adm {
  padding-top: 30px;
  padding-bottom: 40px;
}

.tab-link-prof.login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
  padding-bottom: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 24px;
  line-height: 30px;
}

.tabs-menu-div-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tabs-menu-div-2.parent-tabs {
  min-height: auto;
}

//====================================
//===============MEDIA================
//====================================
//============================991px========================
@media screen and (max-width: 991px) {
  .tab-link-par.parent-tabs {
    max-width: 50%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .tab-link-prof.parent-tabs {
    padding-right: 30px;
    padding-left: 30px;
  }
}
//============================768px========================
@media screen and (max-width: 768px) {
  .tab-link-par {
    min-width: 100px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .tab-link-par.parent-tabs {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tab-link-par.adm {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .tab-link-par.login {
    font-size: 20px;
    line-height: 26px;
  }
  .tab-link-prof {
    min-width: 100px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .tab-link-prof.parent-tabs {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tab-link-prof.adm {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .tab-link-prof.login {
    font-size: 20px;
    line-height: 26px;
  }
}
//============================450px========================
@media screen and (max-width: 450px) {
  .tab-link-par {
    min-width: 90px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .tab-link-par.add-tab.parent-tabs {
    min-width: 80px;
  }
  .tab-link-par.parent-tabs {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .tab-link-prof {
    min-width: 90px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .tab-link-prof.add-tab.parent-tabs {
    min-width: 80px;
  }
  .tab-link-prof.parent-tabs {
    padding-top: 15px;
    padding-bottom: 20px;
  }
}
