//-------------------------------------------------------------//
@mixin placeholderColor($color) {
  &::placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  } /* Firefox 19+ */
  &:-moz-placeholder {
    color: $color;
  } /* Firefox 18- */
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin fluid-type($properties, $min-screen, $max-screen, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@function rem($multiplier) {
  @return ($default-font-size * $multiplier) + px;
}

// Requires the calc-interpolation function which can also be used independently
// Требует функцию calc-интерполяции, которая также может быть использована независимо

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: '+';
  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }
  @return calc(#{$a * 100}vw #{$sign} #{$b});
}

pre {
  font-size: 12px;
  padding: 10px;
  background: white;
  border: solid 1px #777;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

td,
th {
  padding: 0.25rem 0.5rem;
  border: none;
}

// Change scale
$mod_1: 1.067;
$mod_2: 1.3;

// Calculate Modular Scale
// You don't need to calculate this with Sass
// You can use values from http://type-scale.com/
// Or random values :)

$h1-min: $mod_1 * $mod_1 * $mod_1 * $mod_1 * 1rem;
$h1-max: $mod_2 * $mod_2 * $mod_2 * $mod_2 * 1rem;

$h2-min: $mod_1 * $mod_1 * $mod_1 * 1rem;
$h2-max: $mod_2 * $mod_2 * $mod_2 * 1rem;

$h3-min: $mod_1 * $mod_1 * 1rem;
$h3-max: $mod_2 * $mod_2 * 1rem;

$h4-min: $mod_1 * 1rem;
$h4-max: $mod_2 * 1rem;

$h5-min: 1rem;
$h5-max: 1rem;

$h6-min: (1rem / $mod_1);
$h6-max: (1rem / $mod_2);

// Apply fluid type mixin

h1 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h1-min, $h1-max);
}

h2 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h2-min, $h2-max);
}

h3 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h3-min, $h3-max);
}

h4 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h4-min, $h4-max);
}

h5 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h5-min, $h5-max);
}

h6 {
  @include fluid-type(font-size, 37.5rem, 80rem, $h6-min, $h6-max);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0.5rem 0;
  line-height: 1.5;
}

/*    loader   */
@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 1rem;
  height: 1rem;
  margin: 3rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(1) {
  background: rgb(237, 8, 119);
}

.bouncing-loader > div:nth-child(2) {
  background: rgb(0, 181, 208);
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  background: rgb(198, 205, 35);
  animation-delay: 0.4s;
}

.editors-modal {
  background: rgba(0, 0, 0, 0.288);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  display: none;
  &-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &-body {
    pointer-events: auto;
    position: static;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-height: 100%;
  }
  &-dialog {
    max-width: 1050px;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
    display: block;
    padding: 24px;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    //height: 100%;
    min-height: inherit;
    max-height: inherit;
    transform: none;
    opacity: 1;
    position: relative;
    margin: 20px 0;
    &-close {
      cursor: pointer;
      position: absolute;
      right: 3px;
      top: 3px;
    }
    &-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      &__btn-group {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.active-modal {
  opacity: 1;
  display: block;
  z-index: 1000;
}

.return {
  position: absolute;
  top: 30px;
  right: 3%;
  color: rgba(53, 53, 53, 0.5);
  text-align: center;
}

.section-wrapper {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}
