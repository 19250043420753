.class-column._2 {
  display: flex;
  max-width: 78%;
  flex-wrap: nowrap;
  align-items: center;
}

.class-column._2._10 {
  padding-right: 0px;
}

.class-column._1 {
  min-width: 50px;
  padding-left: 20px;
  flex: 0 auto;
}

.class-column._3 {
  display: flex;
  padding-right: 30px;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.class-column._4 {
  flex: 1;
}

.class-column._5 {
  width: 145px;
  max-width: 9vw;
  padding-left: 15px;
  text-align: center;
}

.class-column._6 {
  width: 95px;
  text-align: center;
}

.class-column._7 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
}

.class-column._8 {
  width: 126px;
  max-width: 11vw;
  padding-right: 30px;
  padding-left: 15px;
  text-align: center;
}

.class-column._9 {
  min-width: 50px;
  padding-left: 20px;
  flex: 0 auto;
}

.class-column._10 {
  display: flex;
  padding-right: 13px;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

.class-column._11 {
  min-width: 30px;
  padding-left: 10px;
  flex: 0 auto;
}

.class-photo-column {
  width: 60px;
  padding-right: 20px;
}

.class-name-row {
  display: flex;
  flex-wrap: nowrap;
}

.class-name-column {
  position: relative;
  width: 250px;
  max-width: 16vw;
  padding-right: 20px;
}

.class-name {
  margin-top: 5px;
  margin-bottom: 5px;
}

.class-list-item {
  overflow: hidden;
  transition: all 200ms ease;
}

.class-list-item:hover {
  background-color: rgba(93, 122, 255, 0.05);
}

.class-photo {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-image: url('/assets/images/dashboard/no-photo.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  flex-shrink: 0;
}

.class-photo.no-photo {
  background-image: url('/assets/images/dashboard/no-photo.svg');
}

.class-field {
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px 0px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.1);
  background-color: transparent;
  color: #353535;
  font-size: 15px;
  line-height: 20px;
}

.class-field:focus {
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent #9375fb;
}

.class-field::-webkit-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.class-field:-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.class-field::-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.class-field::placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.class-field.error {
  border-bottom-color: #fd651a;
}
.user-edit-link {
  //display: inline-block;
  //margin-top: 3px;
  margin-left: 30px;
  //padding-left: 20px;
  background-image: url('/assets/images/icons/edit-icon_1.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.user-delete-link {
  width: 6px;
  height: 10px;
  margin-left: 30px;
  background-image: url('/assets/images/icons/delete-icon.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.delete-student {
  cursor: pointer;
  width: 14px;
  height: 18px;
  margin-left: 30px;
  background-image: url('/assets/images/icons/delete-icon.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.delete-student:hover {
  background-image: url('/assets/images/icons/delete-icon-hover.svg');
}

.delete-class {
  width: 14px;
  height: 18px;
  margin-left: 30px;
  background-image: url('/assets/images/icons/delete-icon.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.delete-class:hover {
  background-image: url('/assets/images/icons/delete-icon-hover.svg');
}

.class-li-wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
}

.class-head-row {
  display: flex;
  align-items: flex-start;
}

.class-head-column {
  margin-top: -4px;
  flex: 1;
}

.students-list._2 {
  margin-bottom: -2px;
}

.class-test-row {
  display: flex;
  justify-content: center;
}

.class-test-column {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 10px;
  line-height: 15px;
}

.class-test-column.test {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

.student-name {
  margin-top: 5px;
  font-size: 15px;
}

.student-online-status {
  margin-bottom: 5px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 10px;
  line-height: 15px;
}

.student-online-status.highlighted {
  color: #353535;
}

.prof-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prof-icon {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: -5px;
  margin-left: -5px;
  border-radius: 100px;
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.prof-icon.num {
  background-color: #13e5db;
  box-shadow: 0 0 0 2px #fff;
  line-height: 30px;
}

.advice {
  color: rgba(53, 53, 53, 0.3);
  font-size: 13px;
  font-weight: 400;
}

.advice.highlighted {
  color: #353535;
}

.test-check {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/icons/Test-none.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.test-check.complete {
  background-image: url('/assets/images/icons/test-complete.svg');
}

.test-check.old {
  background-image: url('/assets/images/icons/test-complete2.svg');
}

.class-list-item-2 {
  transition: all 200ms ease;
}

.class-list-item-2:hover {
  position: relative;
  z-index: 3;
}

.class-line {
  position: absolute;
  left: 60px;
  right: 10px;
  bottom: 0px;
  height: 2px;
  background-color: rgba(53, 53, 53, 0.05);
}

.class-line.no-underline {
  display: none;
}

.class-column-text {
  color: rgba(53, 53, 53, 0.3);
  font-size: 13px;
  font-weight: 400;
}

.student-code {
  font-weight: 700;
}

.info-div-class {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-left: 10px;
}

.info-div-class.child-prof {
  top: -2px;
  right: 0px;
}

.student-name-link {
  color: #353535;
  cursor: pointer;
}

.student-name-link.no-link {
  cursor: default;
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .class-column._5 {
    max-width: 12vw;
  }
  .class-column._8 {
    max-width: 100vw;
    padding-right: 15px;
  }
  .class-column._9 {
    display: none;
  }
  .class-photo-column {
    display: none;
  }
  .class-name-column {
    width: auto;
    max-width: 100%;
    flex: 1;
  }
  .class-head-row {
    display: block;
  }

  .class-test-row {
    padding-top: 2px;
  }

  .class-line {
    left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .class-section {
    padding-top: 20px;
  }

  .class-column._2 {
    max-width: 100%;
    flex: 1;
  }
  .class-column._2._10 {
    display: none;
  }
  .class-column._1 {
    position: relative;
    top: 15px;
    align-self: flex-start;
  }
  .class-column._1.head-num {
    position: static;
    align-self: center;
  }
  .class-column._3 {
    flex: 0 auto;
  }
  .class-column._5 {
    display: none;
  }
  .class-column._8 {
    max-width: 100vw;
  }
  .class-column._10 {
    padding-left: 5px;
  }
  .class-photo-column {
    display: none;
  }
  .class-name-row {
    display: block;
    padding-right: 20px;
    flex: 1;
  }
  .class-name-column {
    width: auto;
    padding-right: 0px;
  }
  .class-li-wrapper {
    padding-top: 5px;
  }

  .student-online-status {
    margin-top: 4px;
    line-height: 12px;
  }
}
@media screen and (max-width: 450px) {
  .class-head-row {
    display: flex;
    flex-direction: column;
  }

  .class-test-column.test {
    padding-right: 10px;
    padding-left: 10px;
  }
  .student-name {
    font-size: 13px;
    line-height: 18px;
  }
  .prof-row {
    padding-left: 5px;
  }
  .test-check {
    width: 16px;
    height: 16px;
  }
  .test-check.complete {
    background-size: contain;
  }

  .class-line {
    right: 0px;
  }
}
