prf-page-footer {
  margin-top: auto;
}

.footer-section {
  padding-top: 30px;
  padding-bottom: 10px;
}

.footer-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-right: -30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: rgba(53, 53, 53, 0.3);
  font-size: 13px;
}

.footer-text {
  margin-right: 30px;
  margin-bottom: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.footer-logo {
  margin-right: 30px;
  margin-bottom: 10px;
  .logo-link-footer {
    cursor: auto;
    img {
      display: block;
      opacity: 0.3;
      width: 200px;
      height: auto;
      @media screen and (max-width: 835px) {
        width: 150px;
      }
      @media screen and (max-width: 425px) {
        width: 130px;
      }
    }
  }
}

.footer-text.phone {
  display: none;
}

.footer-link {
  color: rgba(53, 53, 53, 0.4);
  text-decoration: none;
}

.footer-white {
  position: relative;
  z-index: 1001;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 100px;
  background-color: #fff;
  color: rgba(53, 53, 53, 0.4);
  font-size: 12px;
}

.footer-emails {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.logo-link-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 125px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .footer-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768px) {
  .footer-text {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .footer-white {
    position: static;
    z-index: 1;
    padding-left: 0px;
  }
  .footer-emails {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
}
@media screen and (max-width: 450px) {
}
