//=====LOGIN / REGISTRATION=======
.login-container {
  position: relative;
  left: 50%;
  width: 474px;
  max-width: 100%;
  margin-top: 15vh;
  margin-bottom: 110px;
  transform: translate(-50%, 0px);
  text-align: center;
}

.tab-pane.login {
  display: block;
  padding: 40px 46px 48px;
}

.login-section {
  position: relative;
}

.login-label {
  display: flex;
  width: 170px;
  margin-bottom: 0px;
  padding-top: 6px;
  padding-right: 30px;
  align-items: center;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
}

.login-form-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-form-title {
    margin-bottom: 30px;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
  }
}

.login-form-footer {
  .footer_separator {
    margin: 17px 0;
    position: relative;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #353535;
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: calc(50% - 28px);
      height: 2px;
      top: 7px;
      background: #f4f5f4;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  .footer_sign-in {
    margin-top: 16px;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: $color-black2;
    text-align: center;
    .sign-in-link {
      margin-left: 8px;
      text-decoration: underline;
    }
  }
}
.prosvet-registration {
  text-align: center;
  margin-top: 32px;
  .prosvet-link {
    position: relative;
    color: #c7c7c7;
    &:before {
      content: url('/assets/images/prosvet/login_icon_prosvet.svg');
      margin-right: 5px;
    }
  }
}

.login-field {
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  padding: 6px 0px 4px;
  border-style: solid;
  border-color: transparent transparent hsla(0, 0%, 59%, 0.1);
  border-bottom-width: 2px;
  transition: all 200ms ease;
  color: #353535;
  font-size: 15px;
}

.login-field:hover {
  border-bottom-color: #5d7aff;
}

.login-field:focus {
  border-color: transparent transparent #9375fb;
}

.login-field::-webkit-input-placeholder {
  color: #353535;
}

.login-field:-ms-input-placeholder {
  color: #353535;
}

.login-field::-ms-input-placeholder {
  color: #353535;
}

.login-field::placeholder {
  color: #353535;
}

.login-field.invite-field {
  width: 350px;
  font-size: 24px;
}

.login-form-row {
  display: flex;
  margin-bottom: 22px;
  align-items: flex-start;
}

.login-form-row.reg-form {
  margin-top: -20px;
  margin-bottom: 30px;
  align-items: center;
}

.login-field-column {
  flex: 1;
  position: relative;
}

.login-field-column.reg-form {
  display: flex;
  align-items: center;
}

.registration-row {
  display: flex;
  margin-right: -20px;
  margin-bottom: -20px;
  flex-wrap: wrap;
}

.registration-column {
  display: flex;
  width: 50%;
  padding-right: 20px;
  padding-bottom: 20px;
}

.registration-link {
  padding: 28px 10px 26px;
  flex: 1;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  color: #353535;
  text-align: center;
}

.registration-link-image {
  overflow: hidden;
  width: 90px;
  height: 90px;
  border-radius: 150px;
}

.registration-link-image.reg-form {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.registration-link-text {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.registration-link-text.reg-form {
  margin-top: 0px;
}

.login-section {
  overflow: hidden;
}

.policy-text {
  position: relative;
  margin-top: 30px;
  font-size: 13px;
}

.login-form-column {
  width: 150px;
  padding-right: 45px;
}
/* ================================================
==================MEDIA=========================
================================================ */

@media screen and (max-width: 991px) {
}
//=============»========768px================
@media screen and (max-width: 768px) {
  .tab-pane.login {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .login-field.invite-field {
    font-size: 14px;
  }
  .login-form-row.reg-form {
    margin-top: 0px;
  }
  .policy-text {
    margin-right: 20px;
    margin-left: 20px;
  }
}
//================450зч===============
@media screen and (max-width: 450px) {
  .login-container {
    margin-top: 130px;
    margin-bottom: 59px;
  }
  .tab-pane.login {
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 32px;
  }
  .login-section {
    background-color: #fcfbf8;
  }
  .login-title.mobile-title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 56px;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #353535;
  }

  .login-label {
    width: auto;
    margin-bottom: 3px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .login-form-header {
    .login-form-title {
    }
  }

  .login-form-row {
    margin-bottom: 20px;
  }

  .login-form-footer {
    margin-top: 24px;
    .footer_separator {
      margin: 16px 0;
    }
    .footer_sign-in {
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #353535;
      .sign-in-link {
      }
    }
  }
  .login-form-row {
    display: block;
  }
  .login-form-row.reg-form {
    display: flex;
  }
  .registration-link-image {
    width: 60px;
    height: 60px;
  }
  .login-form-column {
    width: auto;
  }
}
